'use client';

import { sendPostRequest } from "@/lib/actions";
import { ApiEndpoints } from "@/lib/appConfig";
import { IApiAction, IBotUserInitData } from "@/types/api";
import { IWebApp } from "@/types/telegram";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useAuth = (webApp: IWebApp) => {
    const { i18n } = useTranslation();

    const authorizeUser = useCallback(async (onSuccess: (user: IBotUserInitData) => void, onException: (apiAction: IApiAction) => void) => {
        const apiResponse = await sendPostRequest<IBotUserInitData | IApiAction>(ApiEndpoints.auth.AUTH_USER, webApp, webApp.initDataUnsafe.user?.id);

        if (apiResponse.success) {
            const user = apiResponse.data as IBotUserInitData;

            if (user.preferred_language !== i18n.language) {
                i18n.changeLanguage(user.preferred_language);
            }

            onSuccess(user);
        } else {
            onException(apiResponse.data as IApiAction);
        }
    }, [i18n, webApp]);

    return authorizeUser;
};