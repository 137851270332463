'use client';

import Loader from "@/components/Loader";
import { useApp } from "../contexts/app-context";
import { useTelegram } from "../contexts/telegram-context";
import { useUi } from "../contexts/ui-context";
import { useAuth } from "../hooks/use-auth";
import { IBotUserInitData } from "@/types/api";
import { useRouter } from "next/navigation";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function HomePage() {
    const { t } = useTranslation();
    const { logger } = useApp();
    const { webApp } = useTelegram();
    const router = useRouter();
    const { handleApiAction, handleApiError } = useApp();
    const authorizeUser = useAuth(webApp);

    const login = useCallback((user: IBotUserInitData) => {
        router.push(user.redirect_url);
    }, [router]);

    const handleAuthorize = useCallback(async () => {
        try {
            await authorizeUser(login, handleApiAction);
        } catch (error: any) {
            handleApiError(error);
            logger.setLogPrefix('HomePage').error(`Authorization faield. ${error.message}`);
        }
    }, [authorizeUser, handleApiAction, handleApiError, login]);

    useEffect(() => {
        handleAuthorize();
    }, [handleAuthorize]);

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            zIndex: 9999
        }}>
            <Loader visible={true} />
        </div>
    );
}
